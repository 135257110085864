/* ==================================================== */
/*                    NORMALIZE 		    	    	*/
/* ==================================================== */
BODY {
    padding-top: $NAVMAIN-MAX-HEIGHT;

    @media (max-width: $SCREEN-SM) {
        padding-top: $NAVMAIN-MIN-HEIGHT;
    }
}

/* ==================================================== */
/*                  NAV (GENERAL) 						*/
/* ==================================================== */
NAV.yf-nav-main  {
    margin: 0;
    height: $NAVMAIN-MAX-HEIGHT;
    background-color: $COLOR-WHITE;
    z-index: 2222;

    .navbar-collapse {
        background-color: $COLOR-WHITE;
    }

    @media (max-width: $SCREEN-SM) {
        height: $NAVMAIN-MIN-HEIGHT;
    }

    // eyefactive main logo
    .yf-logo-main {
        margin-top: 5px;
        height: $NAVMAIN-MAX-HEIGHT - 14px;
        max-height: $NAVMAIN-MAX-HEIGHT;

        @media (max-width: $SCREEN-MD) {
            margin-top: 12px;
            height: $NAVMAIN-MAX-HEIGHT - 26px;
            max-height: $NAVMAIN-MAX-HEIGHT - 26px;
        }

        @media (max-width: $SCREEN-SM) {
            margin-top: 4px; margin-left: 15px;
            height: $NAVMAIN-MAX-HEIGHT - 20px;
            max-height: $NAVMAIN-MAX-HEIGHT - 20px;
        }
    }

    // store logos
    .yf-logo-store {
        height: 26px;
        margin-top: 10px;
    }

    // contact button
    .yf-contact-main-nav {
        position: relative; float: right;
        margin: 14px 15px 0 0;
        SPAN {
            padding: 10px 8px 4px 8px;
        }
        // main links: non-collapsed
        @media (max-width: $SCREEN-SM) {
            SPAN I { padding-right: 4px; padding-left: 4px; font-size: 22px; }
        }
    }

    // country icons round
    .yf-icon-flag-round {
        width: 16px; height: 16px;
        margin-top: -1px;
    }

    // links on the nav bar
    UL.yf-nav {
        margin-top: 21px;

        LI A, LI A:visited {
            font-size: 14px;
            color: $COLOR-K80;
            font-weight: bold;
            text-transform: uppercase;
            //border-bottom: 2px solid transparent;
        }
        LI A.yf-hl {
            color: $COLOR-YFGREEN;
        }
        LI A:active, LI A:focus, LI A:hover {
            color: $COLOR-YFGREEN;
        }
        // main links: non-collapsed
        @media (min-width: $SCREEN-SM) {
            float: right;

            LI A {
                padding: 0 1px 1px 0px;
                margin: 0 0 0 20px;
            }
            LI A:active, LI A:focus, LI A:visited, LI A:hover
            {
                border-bottom: none;// 2px solid $COLOR-YFBLUE;
            }
        }
        @media (min-width: $SCREEN-MD) {
            LI A {
                margin: 0 0 0 25px;
            }
        }

        @media (min-width: $SCREEN-LG) {
            LI A {
                margin: 0 0 0 30px;
            }
        }

        // main links: collapsed
        @media (max-width: $SCREEN-SM) {
            margin-top: 10px;
            color: $COLOR-K65;
        }

        LI.yf-separator-top  {
            border-top: 1px solid $COLOR-K10;
            margin-top: 3px; padding-top: 3px;
        }
    }

    // drop down menu
    UL.yf-drop-down-menu {
        min-width: auto;
        padding: 15px 5px;

        border-top: 2px solid $COLOR-YFBLUE;

        LI UL LI A, LI UL LI A:focus, LI UL LI A:active, LI UL LI A:visited {
            display: block;
            padding: 2px 1px;
            margin: 0px;
            color: $COLOR-K65;
            //font-weight: bold;
            white-space: nowrap;
            font-size: 14px;
            border: none;
            I {
                font-size: 11px;
                padding-right: 5px;
                display: inline-block;
            }
        }

        LI UL LI A:hover {
            color: $COLOR-YFGREEN;
            border: none;
        }
        LI.yf-header, LI.yf-header > A {
            font-size: 18px;
            color: $COLOR-YFGREEN;
            padding-bottom: 5px;
            font-weight: normal;
        }
        LI.yf-special > A {
            color: $COLOR-YFBLUE;
        }
        LI.yf-special > A:hover {
            color: $COLOR-YFGREEN;
        }
    }

    UL.yf-drop-down-menu.yf-big {
        @media (min-width: $SCREEN-SM) {
            min-width: 680px;
            left: -140px;
        }
    }
}


.navbar-default {
    border-color: $COLOR-K10;
}


.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:active,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    color: $COLOR-YFGREEN;
    background-color: $COLOR-WHITE;
}





/* ==================================================== */
/*                  NAV (Breadcrumbs) 					*/
/* ==================================================== */

NAV.yf-breadcrumb {
    OL {
        LI {
            display: inline;


            I, A, A SPAN {
                color: $COLOR-WHITE;
            }

            I {
                padding-left: 5px; padding-right: 5px;
            }

            A:hover {
                text-decoration: underline;
            }

        }
    }
}



/* ==================================================== */
/*                       HEADER 						*/
/* ==================================================== */

// GENERAL
HEADER {
    H1, H2, H3, H4, H5, H6 {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    H2, H3, H4, H5, H6 {
        font-size: 24px;
    }

    P {
        margin-top: 0px;
        margin-bottom: 25px;
        /*@media (min-width: $SCREEN-LG) {
            font-size: 18px;
        }*/
    }


    BUTTON {
        margin-bottom: 30px;
    }
}

@media (min-width: $SCREEN-MD) {
    HEADER > DIV {
        min-height: $HEADER-MIN-HEIGHT-MD;
    }
}
@media (min-width: $SCREEN-LG) {
    HEADER > DIV {
        min-height: $HEADER-MIN-HEIGHT-LG;
    }
}


HEADER.yf-nominheight > DIV {
    min-height: auto;
}


@media (max-width: $SCREEN-SM) {
    HEADER {
        H1 {
            font-size: 36px;
        }
        H2, H3, H4, H5, H6 {
            font-size: 18px;
        }
        P {
            margin-top: 0px;
            margin-bottom: 15px;
        }
        BUTTON {
            margin-bottom: 15px;
        }
    }
}



/*HEADER H1 {
    text-shadow: 1px 1px 5px $COLOR-K80;
}
HEADER DIV.yf-seo {
    H1 { text-shadow: none; }
    H1 + P  { text-shadow: 1px 1px 5px $COLOR-K65; }
}*/



/* ==================================================== */
/*                       FOOTER 						*/
/* ==================================================== */
FOOTER {
    margin:0; padding:10px 0 0 0;
    background-color: $COLOR-WHITE;
    border-top: 1px solid $COLOR-K10;

    DIV.yf-color-divider {
        height: 3px; margin-bottom: 10px;
        @include mixin_yf-bg-linear-dg-yfgreen();
    }

    SPAN {
        display: block;
        margin-bottom: 12px;
        font-size: 18px; color: $COLOR-K80; font-weight: normal;
    }
    SPAN A {
        font-size: 18px; color: $COLOR-K80; font-weight: normal;
        margin-bottom: 24px;
    }

    .yf-footer-links {
        margin-bottom: 24px;
    }
    .yf-footer-links LI {
        margin-bottom: 3px;
    }
    .yf-footer-links LI A {
        //font-size: $FONT-SIZE-SMALL;
        color: #B1B3B4;
        position: relative;
    }

    .yf-footer-links LI A:before {
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        padding-right: 4px;
        //color: $COLOR-YFGREEN;
    }

    .yf-footer-links LI A:hover {
        color: #97BF15;
        text-decoration: none;
    }
    .yf-footer-links SPAN.yf-seperator {
        margin-top: 10px; margin-bottom: 10px;
        width:100px;
        border-bottom: 2px solid #FFFFFF;
    }

    // store logos
    .yf-logo-store {
        height: 24px;
        margin-bottom: 12px;
    }

    .yf-footer-logos {
        padding: 10px 0 15px 0;
        margin-bottom: 30px;
        border-bottom: 1px solid $COLOR-K10;


        IMG {
            margin: 0 auto;
        }
    }

    .yf-footer-action-buttons {
        margin: 0 0 20px 0;
        padding-top: 20px; padding-bottom: 15px;
        border-top: 1px solid $COLOR-K10; border-bottom: 1px solid $COLOR-K10;
    }

    .yf-footer-last {
        padding-top: 10px; padding-bottom: 10px;
        background-color: $COLOR-K5;
        color: #B1B3B4;
        A {
            color: #B1B3B4;
        }
        A:hover {
            color: $COLOR-YFGREEN;
        }
    }
}


/* social icons */
.yf-footer-icons-social {
    margin-bottom: 20px;
    A {
        margin: 3px 6px 3px 6px; display: inline-block;
    }
}

I.yf-icon-social {
    display: table-cell;
    min-width: 50px;
    height: 50px;
    border: 2px solid $COLOR-K10;
    background-color: #FFFFFF;
    border-radius: 50%;
    color: #D9DADB;
    font-size: 28px;
    transition: 0.5s;
    text-align: center;
    vertical-align: middle;
}
I.yf-icon-social:hover {
    border-color: $COLOR-YFGREEN-L;
    background-color: $COLOR-YFGREEN-L;
    color: #FFFFFF;
}



/* Chat & Kontakt-Buttons */
DIV.yf-action-buttons {
    position:fixed;
    bottom:24px;
    right:24px;
    text-align:right;
    vertical-align:bottom;
    z-index: 1111;


    A {
        z-index: 1111;
        display: block;
        width: 52px;
        height: 52px;
        color: $COLOR-WHITE; font-size: 16px; font-weight: 700; line-height: 26px;
        border-radius: 999px;
        padding: 10px 10px 10px 10px;
        margin-top: 12px;
        margin-right: 0px;
        -webkit-box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.2);
    }
    .yf-contact {
        //background: url(../img/icons/icon_mail.png) no-repeat center center;
        background-size: 24px 24px;
        background-color: $COLOR-YFGREEN;
        transition:all 0.5s;
    }
    .yf-contact:hover {
        background-color:$COLOR-YFGREEN-L;
        transform: scale(1.1);
    }
}

/* BUTTON PULSE ANIMATION
DIV.yf-action-buttons {
    animation: yf-pulse 2s;
}
@keyframes yf-pulse {
    0% {
        transform: scale(1.0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1.0);
    }
}*/